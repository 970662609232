<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <h1>Theme</h1>
    <p>Your theme is used for your business branding. It appears on emails, invoices, and pages your customers will see.</p>
    <hr>
    <b-field class="has-bottom-space modal-label" label="Primary Brand Colour">
      <b-input type="color" v-model="theme.brandColorPrimary"></b-input>
    </b-field>
    <b-field class="has-bottom-space modal-label" label="Secondary Brand Colour">
      <b-input type="color" v-model="theme.brandColorSecondary"></b-input>
    </b-field>
    <label class="label modal-label">Update Your Logo</label>
    <file-upload :allowMultiple="false" v-on:processed="updateLogoImageId"></file-upload>
    <label class="label modal-label">Update Your Background</label>
    <file-upload :allowMultiple="false" v-on:processed="updateBackgroundImageId"></file-upload>
    <hr>
    <div class="level is-mobile">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="level-item">
          <a class="button is-light" @click="cancel">Cancel</a>
        </div>
        <div class="level-item">
          <button class="button is-primary" @click="save">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
//MARC Padding on the mouseovers doesnt work
.has-hover-margin {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  //background-color: crimson;
}
//MARC Not sure why this doesnt change the bg color
.filepond--panel-root {
  //background-color: crimson !important;
}
  .themeLogoImage {
    max-width: 200px;
    max-height: 200px;
  }
  .label {
    font-size: 14px;
    margin-bottom: 5px;
    //background-color: crimson;
  }
</style>

<script>

import FileUpload from '@/components/FileUpload'
import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'Theme',
  data () {
    return {
      breadcrumbs: [
        { name: 'Settings', route: 'settings-home' },
        { name: 'Theme', route: null }
      ],
      isLoading: false,
      theme: {
        brandColorPrimary: '#3399FF',
        brandColorSecondary: '#999999',
        brandLogoUrlFileId: null,
        brandBackgroundUrlFileId: null
      },
      labelPosition: 'on-border',
      file: null
    }
  },
  methods: {
    cancel () {
      this.$router.push({ name: 'settings-home' })
    },
    fetchTheme () {
      this.isLoading = true
      this.$store.dispatch('settings/fetchTheme')
        .then((theme) => {
          this.theme.brandColorPrimary = theme.brandColorPrimary
          this.theme.brandColorSecondary = theme.brandColorSecondary
          this.theme.brandLogoUrl = theme.brandLogoUrl
          this.theme.brandBackgroundUrl = theme.brandBackgroundUrl
          this.isLoading = false
        })
    },
    updateLogoImageId (imageId) {
      this.theme.brandLogoUrlFileId = imageId
    },
    updateBackgroundImageId (imageId) {
      console.log('uploadBackgroundImageId', imageId)
      this.theme.brandBackgroundUrlFileId = imageId
    },
    save () {
      this.isLoading = true
      this.$store.dispatch('settings/saveTheme', this.theme)
        .then((response) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: 'Theme saved.',
            type: 'is-success'
          })
          this.$store.dispatch('account/fetch')
            .then(() => {
              this.$router.push({ name: 'settings-home' })
            })
        })
        .catch(err => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${err.message}`,
            type: 'is-danger'
          })
        })
    }
  },
  mounted () {
    this.fetchTheme()
  },
  components: { Breadcrumbs, FileUpload }
}
</script>

<style>

</style>
